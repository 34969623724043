import { ChangeEvent } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

type Props = {
    value?: string;
    name?: string;
    placeholder?: string;
    size?: "lg" | "sm";
    required?: boolean;
    autoFocus?: boolean;
    className?: string;
    onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
};

const TimeInput: React.FC<Props> = ({ value, name, size, required, autoFocus, className, onChange }) => {
    const handleHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
        let [hours, minutes] = value?.split(":") ?? ["0", "00"];
        hours = event.target.value;

        const time = `${hours}:${minutes}`;
        onChange?.(time, event);
    };

    const handleMinutesChange = (event: ChangeEvent<HTMLInputElement>) => {
        let [hours, minutes] = value?.split(":") ?? ["0", "00"];
        minutes = event.target.value;

        const time = `${hours}:${minutes}`;
        onChange?.(time, event);
    };

    return (
        <InputGroup className={className} type="dropdown">
            <Input
                type="number"
                name={`${name || ""}-hours`}
                placeholder="ЧЧ"
                bsSize={size || "lg"}
                required={required}
                autoFocus={autoFocus}
                onChange={handleHoursChange}
                min="0"
                max="23"
            />
            <InputGroupText>:</InputGroupText>
            <Input
                type="number"
                name={`${name || ""}-minutes`}
                placeholder="ММ"
                bsSize={size || "lg"}
                required={required}
                onChange={handleMinutesChange}
                min="0"
                max="59"
            />
        </InputGroup>
    );
};

export default TimeInput;
