import clsx from "clsx";
import { PropsWithChildren, useState } from "react";
import attachmentsService from "../../../services/attachmentsService";
import routesService from "../../../services/routesService";
import { RouteStatus } from "../../../models/enums/routeStatus";
import ListItemCard from "../../../components/containers/listItemCard";
import FileInput from "../../../components/inputs/fileInput";
import ImageGallery from "../../../components/containers/imageGallery";
import { NextWorksId, Route } from "../../../models/routes/route";
import { FileInfo } from "../../../models/routes/fileInfo";
import LoadingButton from "../../../components/buttons/loadingButton";
import routePointsService from "../../../services/routePointsService";
import TimeInput from "../../../components/inputs/timeInput";

export type NextWorkCardProps = PropsWithChildren & {
    route: Route;
    caption: string;
    onChangeRoute: (route: Route) => void;
    canEnterTimeSpent?: boolean;
};

export const NextWorkCard = ({ route, caption, onChangeRoute, children, canEnterTimeSpent }: NextWorkCardProps) => {
    const [actionInProgress, setActionInProgress] = useState(false);
    const [timeSpent, setTimeSpent] = useState("0:00");

    const handleAddPhotos = async function* (files: File[]) {
        const addedFiles: FileInfo[] = [];

        for (const file of files) {
            const fileName = `МЛ_${file.name}`;
            const { isOk, data: fileInfo } = await attachmentsService.upload(file, route.orderId, undefined, fileName);

            if (!isOk || !fileInfo) return;

            fileInfo.data = file;
            fileInfo.name = file.name;

            addedFiles.push(fileInfo);

            const newRoute = { ...route };
            newRoute.files = newRoute.files ? [...newRoute.files, ...addedFiles] : [...addedFiles];

            if (route.status !== RouteStatus.WaitingForFinishConfirmation) {
                const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.WaitingForFinishConfirmation);
                if (isOk && data) newRoute.status = data.status;
            }

            onChangeRoute(newRoute);

            yield fileInfo.guid;
        }
    };

    const handleRemovePhotos = async (files: FileInfo[]) => {
        let actualFiles = [...route.files!];

        for (const file of files) {
            const { isOk } = await attachmentsService.remove(file.guid);
            if (!isOk) continue;

            actualFiles = actualFiles?.filter((f) => f.guid !== file.guid);

            const newRoute = { ...route, files: actualFiles };

            if (route.status === RouteStatus.Finished && actualFiles.length === 0) {
                const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.WaitingForRouteSheet);
                if (isOk && data) newRoute.status = data.status;
            }

            onChangeRoute(newRoute);
        }
    };

    const roundTimeSpent = (time: string) => {
        const [hours, minutes] = time.split(":");
        return `${parseInt(minutes) >= 15 ? parseInt(hours) + 1 : hours}`;
    };

    const handleFinishButtonClick = async () => {
        setActionInProgress(true);

        const newRoute = { ...route };

        if (route.status !== RouteStatus.Finished) {
            const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.Finished, RouteStatus.Finished);
            if (isOk && data) newRoute.status = data.status;
        }

        if (route.nextWorksCheckListId === NextWorksId.WaybillAndTimeSpentForBicycles) {
            const commentsChanges = {
                "Services[0].ServiceId": "1",
                "Services[0].Comments": "Затрачено времени: " + roundTimeSpent(timeSpent) + " ч.",
            };

            const { isOk, data: newPoint } = await routePointsService.patch(route.orderId, commentsChanges);
            if (!isOk || !newPoint) return;
        }

        onChangeRoute(newRoute);
        setActionInProgress(false);
    };

    const onFinishRoute = async () => {
        setActionInProgress(true);

        const newRoute = { ...route };

        if (route.status < RouteStatus.WaitingForRouteSheet) {
            const { isOk, data } = await routesService.setStatus(
                route.orderId,
                RouteStatus.WaitingForFinishConfirmation,
                RouteStatus.WaitingForFinishConfirmation,
            );
            if (isOk && data) newRoute.status = data.status;
        }

        onChangeRoute(newRoute);
        setActionInProgress(false);
    };

    const onStartRoute = async () => {
        setActionInProgress(true);

        const newRoute = { ...route };

        if (route.status !== RouteStatus.InWork) {
            const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.InWork, RouteStatus.InWork);
            if (isOk && data) newRoute.status = data.status;
        }

        onChangeRoute(newRoute);
        setActionInProgress(false);
    };

    const routeFinished = route.status === RouteStatus.Finished;
    return (
        <>
            <div className="my-2 mt-4">
                <div className="fw-bold text-center">{caption}</div>
            </div>
            {route.status === RouteStatus.Confirmed ? (
                <ListItemCard className="border-start border-5 border-success">
                    <LoadingButton
                        caption="Начать маршрут"
                        loading={actionInProgress}
                        disabled={actionInProgress}
                        className="w-100 mt-3"
                        color="primary"
                        onClick={onStartRoute}
                    />
                </ListItemCard>
            ) : (
                <ListItemCard className={clsx("border-start border-5", routeFinished ? "border-success" : "border-warning")}>
                    {route.status < RouteStatus.WaitingForRouteSheet &&
                    route.nextWorksCheckListId === NextWorksId.WaybillAndTimeSpentForBicycles ? (
                        <LoadingButton
                            caption="Завершить маршрут"
                            loading={actionInProgress}
                            disabled={actionInProgress}
                            className="w-100 mt-3"
                            color="primary"
                            onClick={onFinishRoute}
                        />
                    ) : (
                        <>
                            {children}
                            {canEnterTimeSpent && !routeFinished && (
                                <TimeInput
                                    value={timeSpent}
                                    name="Затраченное время"
                                    required
                                    className="text-center mb-2"
                                    onChange={(time) => setTimeSpent(time)}
                                />
                            )}
                            <FileInput
                                files={route.files ?? []}
                                accept="image/*"
                                multiple={true}
                                className="mt-2"
                                caption="Прикрепить"
                                buttonColor="primary"
                                onAddFiles={handleAddPhotos}
                                onRemoveFiles={handleRemovePhotos}
                                renderFileItems={(files, onRemoveFiles) => (
                                    <ImageGallery images={files} onRemoveFiles={onRemoveFiles} />
                                )}
                            />
                            {!routeFinished && (
                                <LoadingButton
                                    caption="Готово"
                                    loading={actionInProgress}
                                    disabled={actionInProgress || route.files?.length === 0 || canEnterTimeSpent && timeSpent === "0:00"}
                                    className="w-100 mt-3"
                                    color="primary"
                                    onClick={handleFinishButtonClick}
                                />
                            )}
                        </>
                    )}
                </ListItemCard>
            )}
        </>
    );
};
